.section-quote {
    padding: 120px 0 10px;
    background: $g_black;
    color: $g_white;
    blockquote {
        margin: 0 12%;
        @include font-size(35px);
        font-style: italic;
        @include bp(xxl) {
            margin: 0 24.99%;
        }
    }
}

.section-intro {
    padding: 40px 0 75px;
    background: $g_black;
    color: $g_white;
    @include bp(sm) {
        padding: 100px 0 120px;
    }
    h2 {
        margin: 0 0 30px;
        @include font-size(20px);
        font-weight: 700;
        color: $g_white;
        line-height: 36px;
        @include bp(sm) {
            margin: 0 0 50px;
            @include font-size(28px);           
            line-height: 46px;            
        }
    }
    p {
        @include font-size(16px);
        line-height: 36px;
    }
    .btn {
        display: block;
        margin: 0;
        padding: 14px 0 0;
        color: $g_white;
        border-color: $g_white;
        &:hover, &:focus {
            color: $g_black;
            background: $g_primary;
        }
    }
}

.section-image {
    padding: 60px 0 0;
    background: #202020;
    img {
        display: block;
        margin: 0 auto;
    }
}

.section-our-story {
    padding: 60px 0 0;
    background: #202020;
    color: $g_white;
    h2 {
        margin: 0 0 30px;
        @include font-size(20px);
        font-weight: 700;
        color: $g_white;
        line-height: 36px;
        @include bp(sm) {
            margin: 0 0 50px;
            @include font-size(28px);           
            line-height: 46px;            
        }
    }
    p {
        @include font-size(16px);
        line-height: 36px;
        margin: 0 8.333% 0 0; 
    } 
    .btn {
        display: block;
        margin: 40px 0 0;
        padding: 14px 0 0;
        color: $g_white;
        border-color: $g_white;
        &:hover, &:focus {
            color: $g_black;
            background: $g_primary;
        }
    }
}

.section-social-feed {
    padding: 65px 0;
    background: #202020;
    color: $g_white;
    @include bp(sm) {
        padding: 125px 0;
    }
    .crt-grid {
        .crt-feed-more {
            a {
                color: $g_primary;
                background: none;
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
    &__hash-tag {
        display: block;
        font-family: $g_font-alt;
        @include font-size(28px);
        font-weight: 700;
        text-align: center;
    }
    #social-feed {
        margin: 0 auto 20px;
    }
    .crt-filter {
        display: none;
    }
}