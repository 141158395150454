html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    background: #000;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

svg.icon {
	// unintuitive, this allows SVG's to be clicked.
	pointer-events: none;
}

.screen-reader-text {
    position: absolute;
    top: 0;
    visibility: hidden;
}