/* ==========================================================================
   Grid
   ========================================================================== */

.grid,
.row {
	@include clearfix;
	margin: 0 auto;
	max-width: 1392px;
}

.grid {
}

/* ==========================================================================
	row and column
========================================================================== */
$gutter: 1em;
$cols: 12;

[class*='row__col-'] {
	float: left;
	width: 100%;
    min-height: 1px;
    padding: 0 20px;
    @include bp(sm) {
	   padding: 0 $gutter;
    }
}

.row {
	// margin: 0 (-$gutter \2);

	&__col {
        @include bp(sm) {
            @for $i from 1 to $cols {
                &--#{$i} {
                    width: percentage($i / $cols);
                }
            }
        }

		@include bp(xs) {
			&-xs {
				@for $i from 1 to $cols + 1 {
					&--#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}


		@include bp(sm) {
			&-sm {
				@for $i from 1 to $cols + 1 {
					&--#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}


		@include bp(md) {
			&-md {
				@for $i from 1 to $cols + 1 {
					&--#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}


		@include bp(lg) {
			&-lg {
				@for $i from 1 to $cols + 1 {
					&--#{$i} {
						width: percentage($i / $cols);
					}
				}
			}
		}
	}
}