img {
	max-width: 100%;
	height: auto;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.centered {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.align-left {
	text-align: left;
	display: block;
}

.align-right {
	text-align: right;
	display: block;
}

.nobr {
	white-space: nowrap
}

.mobile-only {
	display: block;
	@include bp(xs) {
		display: none;
	}
}

.css--failed {
  display: none !important;
}

.css--passed {
  display: block !important;
}
