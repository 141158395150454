.section-newsletter {
    padding: 70px 0;
    color: $g_white;
    background: $g_black;
    font-family: $g_font-alt;
    @include font-size(14px);
    text-align: center;
    @include bp(sm) {
        padding: 120px 0;
    }
    P {
        margin: 0 0 40px;
    } 
    position: relative;
    input[type=email] {
        width: 100%;
        height: 62px;
        line-height: 62px;
        margin: 0;
        padding: 0;
        font-family: $g_font-default;
        @include font-size(18px);
        font-style: italic;
        border: 1px solid $g_white;
        border-radius: 1px;
        background: $g_black;
        text-align: center;
        color: #fff;
    }
    .pirate-forms-fields-container {
        position: relative;
    }
    .pirate_forms_wrap .form_field_wrap .pirate-forms-submit-button {
        position: absolute;
        top: 50%;
        right: 30px;
        display: block;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
        background: #000 url(../images/btn-newsletter.svg) center no-repeat;
        background-size: 6px 11px;
        border: none;
        transform: translate(0, -50%);
        &:after {
            display: none;
        }
    }
    input:-webkit-autofill {
        color: #fff !important;
        background-color: #000 !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }    
}