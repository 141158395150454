// CUSTOM FONT
// ------------------------------------------------
$fontFilePath: '../fonts/';
$fontFamilyName: 'Merriweather';


// ICON FONT
// ------------------------------------------------
$iconVersion: '?xipax';
$iconfontFamily: 'icomoon';

@font-face {
	font-family: '$iconfontFamily';
	src:  url('#{$fontFilePath}/#{$iconfontFamily}.eot#{$iconVersion}');
	src:  url('#{$fontFilePath}/#{$iconfontFamily}.eot#{$iconVersion}#iefix') format('embedded-opentype'),
		url('#{$fontFilePath}/#{$iconfontFamily}.ttf#{$iconVersion}') format('truetype'),
		url('#{$fontFilePath}/#{$iconfontFamily}.woff#{$iconVersion}') format('woff'),
		url('#{$fontFilePath}/#{$iconfontFamily}.svg#{$iconVersion}#{$iconfontFamily}') format('svg');
	font-weight: normal;
	font-style: normal;
}



[class^="icon-"], [class*=" icon-"], .font-icon {
	font-family: '$iconfontFamily';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-ico-mail:before {
  content: "\e900";
}
.icon-ico-fb:before {
  content: "\e901";
}
.icon-ico-insta:before {
  content: "\e902";
}