/* ==========================================================================
Typography
========================================================================== */
body {
	color: $g_text-colour;
	font-family: $g_font-default;
    @include font-size(16px);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

p {
    margin: 0 0 30px;
    line-height: 24px;
}

h1, h2, h3, h4 {
	color: $g_black;
	font-family: $g_font-alt;
	font-weight: normal;
	line-height: normal;
}

h1 {
	@include font-size(30px);
    font-weight: 400;
	margin: 0;
    color: $g_white;
    @include bp(sm) {
        @include font-size(36px);
    }
}

h2, .elementor-widget-heading.elementor-widget-heading .elementor-heading-title {
	font-family: $g_font-alt;
	@include font-size(24px);
    font-weight: 700;
	line-height: normal;
	margin: 0 0 10px;
	color: $g_black;
}

h3 {
    font-family: $g_font-alt;
    @include font-size(20px);
    font-weight: 700;
    color: $g_black;
	margin: 0;
}

h4 {
	@include font-size(18px);
	margin: 0;
}

blockquote {
    font-family: $g_font-alt;
    @include font-size(28px);
    font-weight: 700;
    line-height: 46px;
    margin: 0 24.99999% 70px;
    padding: 0 0 0 30px;
    border-left: 6px solid $g_primary;
}

a {
	color: $g_bluedenim;
	text-decoration: none;
	background: transparent;

	&:active,
	&:hover,
	&:focus {
		color: $g_bluedenim;
		text-decoration: underline;
		outline: 0
	}
}

ul {
    margin: 0 0 30px 20px;
    padding: 0;
    list-style-position: outside;
    li {
        margin: 0 0 5px;
    }
}

.clear {
    clear: both;
    display: block;
}