.header__logo-mobile {
    @include bp(md) {
        display: none;
    }
}

nav {
    display: block;
    ul {
        position: relative;
        z-index: 50;
        width: auto;
        @include list-no-style();
        margin: 50px 0 0;
        padding: 0;
        @include bp(sm) {
            display: inline-block;
            margin: 0;  
            float: right;
            @include font-size(0px);
            text-align: right;
            background: rgba(43,45,43,0.7);
        }
        li {
            display: block;
            margin: 0;
            color: $g_white;
            @include font-size(16px);
            font-family: $g_font-alt;
            text-transform: none;
            @include bp(sm) {
                display: inline-block;
                color: $g_text-colour;
            }
            a {
                display: block;
                color: $g_white;
                padding: 20px;
                @include bp(sm) {
                    padding: 15px 20px;
                }
                &:hover, &:focus {
                    color: $g_white;
                    background: $g_secondary;
                    text-decoration: none;
                }
            }
            &.current-menu-item {
                a {
                    color: $g_white;
                    background: $g_black;
                    text-decoration: none;
                }
            }
            &:first-child {
                &.current-menu-item {
                    a {
                        color: $g_white;
                        background: $g_secondary;
                    }
                }
            }
        }
    }
}


/* ==========================================================================
   Navigation
   ========================================================================== */

$border-colour: rgba($g_white, 0.10);
body {
    &.menu-slider {
        @include bp(sm, max) {
            overflow: hidden;
            background: $g_black;
        }
    }
}

#site-wrapper {
    background: $g_white;
    @include bp(sm, max) {
        position: relative;
        left: 0; //change to right for right side push menu
        transition: left 0.35s ease; //change to right for right side push menu
    }
    @include bp(sm) {
        max-width: 95%;
        margin: 0 auto;
    }
    @include bp(xxl) {
        max-width: none;
    }
}

.navbar {
    position: static;
    left: 0; //change to right for right side push menu
    transition: left 0.35s ease; //change to right for right side push menu
    @include clearfix();
    .container {
        .navbar-collapse {
            @include bp(sm, max) {
                position: absolute;
                top: 0;
                left: -180%; //double the menu size - change to right for right side push menu
                display: block;
                width: 90%;
                padding: 0;
                margin: 0;
                transition: left 0.35s ease; //change to right for right side push menu
            }
            &.collapsing {
                transition: left 0.35s ease; //change to right for right side push menu
            }
            &.in {
                margin: 0;
            }
            @include bp(sm) {
                position: static;
                width: 100%;
                background: none;
                padding: 0;
            }
        }
    }
}

body.menu-slider .navbar3 .navbar-collapse {
    @include bp(sm, max) {
        left: -90%; //change to right for right side push menu
    }
}

body.menu-slider #site-wrapper,
body.menu-slider .navbar {
    @include bp(sm, max) {
        transition: left 0.35s ease; //change to right for right side push menu
        left: 90%; //change to right for right side push menu
    }
}

.nav-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    text-indent: -9999px;
    background: $g_white url('../images/nav-toggle.svg') no-repeat;
    background-size: 100% 100%;
    border: none;
    @include bp(sm) {
        display: none;
    }
}

.nav-toggle-close {
    position: absolute;
    top: 13px;
    right: 17px;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    background: url('../images/nav-toggle-close.svg') no-repeat;
    background-size: 80% 80%;
    background-position: center;
    border: none;
    @include bp(sm) {
        display: none;
    }
}

.toggle-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    display: block;
    width: 46px;
    height: 46px;
    background: url('../nav-toggle-arrow.svg') no-repeat;
    background-size: 24px 15px;
    background-position: center;
    transition: all 0.3s linear;
    @include bp(sm) {
        display: none;
    }
}

nav {
    ul {

        li {
            &:hover, &:focus {
                >ul {
                    @include bp(sm) {
                        left: 0;
                        ul, li:hover ul, li:focus ul, li.hover ul {
                            display: none !important;
                        }
                    }
                }
            }
            ul {
                li {
                    a {
                        padding: 15px 1.5em;
                        line-height: 1em;
                        .toggle-icon {
                            top: 0;
                            right: 4px;
                            background: url('../nav-toggle-arrow-sub.svg') no-repeat;
                            background-size: 20px 10px;
                            background-position: center;
                            transform: rotate(180deg);
                        }
                        @include bp(sm, max) {
                            color: $g_text-colour;
                        }
                        &:focus,
                        &:hover {
                            @include bp(sm, max) {
                                color: $g_text-colour;
                            }
                        }
                    }
                }
            }
        }
    }
}
