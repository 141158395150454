.btn {
    position: relative;
    display: inline-block;
    width: 191px;
    height: 43px;
    line-height: normal;
    padding: 14px 0 0;
    box-sizing: border-box;
    font-family: $g_font-alt;
    @include font-size(12px);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #333;
    border: 1px solid #999;
    border-radius: 1px;
    cursor: pointer;
    &:after {
        position: absolute;
        top: 50%;
        right: -36px;
        display: block;
        content: '';
        width: 48px;
        height: 1px;
        background: $g_primary;
        transform: translate(0, -50%);
    }
    &:hover, &:focus {
        color: $g_black;
        background: $g_primary;
    }
}

button.btn {
    padding: 0;
}

.section-content-internal .pirate_forms_wrap .form_field_wrap .pirate-forms-submit-button {
    width: 191px;    
}