.form-generic, .pirate_forms {    
    input[type=text], input[type=tel], input[type=email] {
        width: 100%;
        height: 62px;
        line-height: 62px;
        margin: 0 0 38px;
        padding: 0 20px;
        box-sizing: border-box;
        font-family: $g_font-default;
        @include font-size(18px);
        font-style: italic;
        border: 1px solid #ccc;
        border-radius: 1px;
        text-align: left;
    }
    textarea {
        width: 100%;
        min-height: 160px;
        margin: 0 0 38px;
        padding: 20px;
        box-sizing: border-box;
        font-family: $g_font-default;
        @include font-size(18px);
        font-style: italic;
        border: 1px solid #ccc;
        border-radius: 1px;
        text-align: left;        
    }
    .contact_submit_wrap {
        text-align: left;
    }
}

input:-webkit-autofill {
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.wpforms-container-full {
    max-width: 500px;
}

div.wpforms-container-full .wpforms-form {
    input.wpforms-field-medium, .wpforms-field-row.wpforms-field-medium {
        max-width: 100%;
    }
}

.section-content {
    div.wpforms-container-full .wpforms-form {
    input.wpforms-field-medium, .wpforms-field-row.wpforms-field-medium {
        max-width: 70%;
    }
}   
}