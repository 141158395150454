.table-container {
    width: 100%;
    margin: 0 0 40px;
    overflow-x: scroll;
    @include bp(sm) {
        overflow-x: auto;
    }
    &.pricing-table {
        @include bp(sm) {
            width: 40%;
            float: left;
            padding: 0 0 0 8.3333%;
        }
        table, h3 {            
            @include bp(sm) {
                padding: 0;
            }
        }
        + .pricing-table {
            @include bp(sm) {
                float: right;
                padding: 0 8.3333% 0 0;
            }
        }
    }
}

table {
    width: 100%;
    margin: 0;
    overflow: hidden;
    tr {
        border: none;
    }
    th {
        @include font-size(20px);
        font-weight: 400;
        font-family: $g_font-alt;
        text-align: left;
        color: $g_black;
        vertical-align: top;
    }
    td {
        width: 50%;
        padding: 0 40px 0 0;
        box-sizing: border-box;
        border: none;
        vertical-align: top;
        h3 {
            margin: 0;
        }
    }
    &.fleet {
        td {
            display: block;
            width: 100%;
            margin: 0 0 20px;
            @include bp(sm) {
                display: table-cell;
                width: 50%;
                margin: 0;
            }
            &:nth-child(2) {
                @include bp(sm) {
                    width: 10%;
                }
            }
            &:nth-child(3), &:nth-child(4) {
                @include bp(sm) {
                    width: 20%;
                }
            }
            &:nth-child(4) {
                @include bp(sm) {
                    padding: 0;
                }
            }
        }
    }
    &.pricing {
        td {
            margin: 0 0 20px;
            @include bp(sm) {
                display: table-cell;
                width: 25%;
                margin: 0;
                &:first-child {
                    width: 75%;
                }
                &:nth-child(2) {
                    text-align: right;
                    padding: 0;
                }
            }
        }
    }
}