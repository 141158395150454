/* ==========================================================================
   Accordion
   ========================================================================== */

$accordion--border: 1px solid #ccc;
$accordion--background: $g_white;
$accordion--highlight: linear-gradient(to bottom, rgba(51,51,51,1) 0%,rgba(0,0,0,1) 100%);
$accordion--secondary-colour: $g_white;
$accordion--text-colour: #333;
$accordion--padding: 6rem;
$accordion--transition: 500ms ease-in-out;

.accordion {
    margin: 0 0 40px;
    color: $accordion--text-colour;
    &__header {
        display: block;
        position: relative;
        width: 100%;
        margin: 0 0 10px;
        padding: 1em 3em 1em 0.8em;
        font-family: $g_font-default;
        @include font-size(20px);
        text-align: left;
        line-height: 1;
        text-decoration: none;
        color: $accordion--text-colour;
        border: none;
        border-radius: 5px;
        background: #ccc;
        cursor: pointer;
        @include bp(sm) {
            margin: 0 0 20px;
            padding: 0.5em 6em 0.5em 1.5rem;
            @include font-size(30px);
        }
        &:hover, &:focus {
            color: $g_black;
            outline: none;
        }
        &:after {
            position: absolute;
            top: 25px;
            right: 20px;
            content: '';
            transition: opacity $accordion--transition, transform 400ms ease-in-out;
            @include bp(sm) {
                top: 0.9em;
                right: 1.5em;
                transform: translate(0,0);
            }
        }
        &:after {
            width: 19px;
            height: 12px;
            background: url(../images/accordion-arrow.svg) no-repeat;
            @include bp(sm) {
                width: 27px;
                height: 13px;
            }
        }
        &:hover:after, &:focus:after {
            background: url(../images/accordion-arrow-white.svg) no-repeat;
        }
        &[aria-expanded="true"] {
            background: $accordion--highlight;
            border-radius: 5px 5px 0 0;
            color: $g_white;
            &:after {
                background: url(../images/accordion-arrow-white.svg) no-repeat;
                transform: rotate(180deg);
                @include bp(sm) {
                    transform: translate(0,0) rotate(180deg);
                }
            }
            &:hover, &:focus {
                color: $g_white;
                outline: none;
            }
        }
    }
    &__title {
        @include font-size(18px);
        line-height: 1.50em;
    }
    &__panel {
        margin: -20px 0 20px;
        padding: 0 1.2rem 1rem;
        border: $accordion--border;
        border-top: none;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        background: $accordion--background;
        transition: all $accordion--transition;
        box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.1);
        @include font-size(17px);
        @include bp(sm) {
            padding: 0 2rem 2rem;
        }
        &:focus {
            outline: none;
        }
        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            border-top: 1px solid #d9d9d9;
            margin: 0 0 2rem;
        }
        i {
            @include font-size(18px);
            font-weight: 700;
            @include bp(sm) {
                line-height: 2em;
            }
        }
        table {
            margin: 0 0 20px;
            td {
                display: block;
                padding: 0 0 5px;
                @include bp(sm) {
                    display: table-cell;
                    padding: 0 0 20px;
                }
                &:first-child {
                    width: 120px;
                }
            }
        }
        ul {
            margin: 0 0 40px;
        }
        p {
            margin: 0 0 15px;
            padding: 0;
        }
        .btn {
            width: auto;
            margin: 0 0 40px
        }
    }
}