.footer {
    padding: 20px 0;
    @include font-size(12px);
    color: $g_white;
    background: #202020;
    a {
        color: $g_white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    p {
        display: block;
        text-align: center;
        margin: 0 0 25px;
        padding:  0;
        @include bp(sm) {
            display: inline-block;
            margin: 0;
            text-align: left;
        }
    }
    &__logo {
        display: block;
        margin: 0 auto;
        width: 165px;
        height: 55px;
    }
    &__links {
        display: block;
        @include list-no-style();
        text-align: center;
        padding: 20px 0 0;
        @include bp(sm) {
            width: 100%;
            display: inline-block;
            text-align: right;
        }
        li {
            display: inline-block;
        }
    }
}