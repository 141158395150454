.section-social-icons {
    background: #202020;
    &__container {
        width: 100%;
        height: 90px;
        box-sizing: border-box;
        padding: 36px 0 0;
        text-align: center;
        background: rgb(240,192,63);
        background: linear-gradient(to right, rgba(240,192,63,1) 0%,rgba(237,177,18,1) 100%);
    }
    &__icon {
        color: $g_black;
        @include font-size(18px);
        span {
            display: inline-block;
            span {
                text-indent: -9999px;
            }
        }
    }
    &__spacer {
        display: inline-block;
        width: 138px;
        height: 1px;
        margin: 0 22px;
        vertical-align: middle;
        background: #775b10;
    }
}