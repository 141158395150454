.section-slider {
    display: block;
    margin: 0 auto;
    @include bp(md) {
//        width: 1100px;
        max-width: 100%;    
    }
    @include bp(xxl) {
        width: 1600px;
        max-width: none;
        height: 885px;        
    }
    img {
        display: block;
        width: 100%;
        margin: 0;
    }
    .ms-thumb-list {
        display: none;
        @include bp(sm) {
            display: block;
            right: 44px !important;
        }
    }
}
