@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}
// Example usage - @include font-size(14px)

@mixin bp($breakpoint:null, $minormax:min, $widthorheight:width) {

	@if not map-has-key((max:0,min:0), $minormax)  {
		$minormax: min;
	}

	@if not map-has-key((width:0,height:0), $widthorheight)  {
		$widthorheight: width;
	}

	@if map-has-key(fetch($breakpoints, $widthorheight), $breakpoint) {
		$breakpoint: fetch($breakpoints, $widthorheight, $breakpoint);
		@if $minormax == max {
			$breakpoint: $breakpoint - 1px;
		}
	}

	@if $breakpoint {
		@media screen and (#{$minormax}-#{$widthorheight}: calculateEm($breakpoint)) { @content; }
	}
}

@mixin z($zindex:null) {
	z-index: fetch($z, $zindex);
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin list-no-style {   
    list-style: none;
    margin: 0;
    padding: 0;
}