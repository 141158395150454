.featured-image {
    img {
        width: 100%;
    }
}

.content {
    padding: 20px 0;
    @include bp(md) {
        padding: 70px 50px 50px;
    }
}
.section-page-header {
    position: relative;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    overflow: hidden;
    @include bp(xs) {
        width: auto;
        height: auto;
        overflow: visible;
    }   
    img {
        display: block;
        width: auto;
        height: 200px;
        max-width: none;
        margin: 0;
        @include bp(xs) {
            width: 100%;
            height: auto;
        }
    }
    h1 {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
        width: 90%;
        padding: 10px 20px;
        background: $g_primary;
        @include bp(sm) {
            position: absolute;
            bottom: 30px;
            left: 50%;
            width: 94%;
            transform: translate(-50%, 0);
        }
    }
}

.section-content-internal {
    display: block;
    margin: 140px 0 0;
    padding: 80px 0;
    line-height: 36px;
    @include bp(sm) {
        margin: 360px 0 0;        
    }
    &.header-img {
        padding: 80px 0 0;
        img {
            width: 100%;
            margin: 0;
        }
    }
    h2, h3, h4, p, .form-generic, .pirate_forms_wrap, table, ul {
        @include bp(sm) {
            padding: 0 8.3333%;
        }
    }
    table {
        h2, h3, h4, p, table, ul {
            padding: 0;
        }
        table {
            margin: 0;
            padding: 0;
        }
    }
    img {
        display: block;
        margin: 0 auto 40px;
    }
    &.section-content-alt {
        + .section-content-alt {
            margin: 90px 0 0;
        }
    }
    + .section-content-internal {
        margin: 0;
    }
}

img.alignright {
    min-width: 100%;
    margin: 0 0 30px;
    @include bp(sm) {
        display: inline-block;
        min-width: 0;
        clear: right;
        float: right;
        margin: 0 0 30px 30px;
    }
}