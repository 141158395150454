.header {
    position: relative;
    z-index: 20;
    width: 100%;
    max-width: 1392px;
    margin: 0 0 70px;
    color: $g_text-colour;
    @include font-size(12px);
    text-transform: uppercase;
    @include bp(sm) {
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: 50;
        max-width: 95.5%;
        margin: 0;
        transform: translate(-50%, 0);        
    }
    @include bp(xxl) {
        max-width: none;
    }
    &__logo {
        display: block;
        @include bp(sm, max) {
            display: block;
            position: absolute;
            top: 30px;
            left: 50%;
            width: 60%;
            transform: translate(-50%, 0);
            text-align: center;
        }
        @include bp(sm) {
            padding: 0 10px;
        }
    }
    &__nav {
        @include clearfix();
        @include bp(sm) {
            margin: 10px 0 0;
            padding: 0 20px;
        }
        .grid {
            background: $g_white;            
        }
    }
    .contact {
        position: absolute;
        top: 0;
        right: 70px;
        display: block;
        padding: 20px;
        color: $g_black;
        @include font-size(12px);
        @include bp(sm) {
            position: static;
            display: inline-block;
            padding: 60px 20px;
        }
        span {
            display: inline-block;
            @include bp(sm) {
                margin-top: 1px;
            }
            span {
                text-indent: -9999px;
            }
        }
        &:hover, &:focus {
            color: $g_white;
            background: $g_black;
        }
    }
}